import React, {useState, useRef, useEffect} from "react"
import { connect } from "react-redux"
import Formulario from '../Contact.jsx'
import Card from '../property-card'

import {getDescription,descriptionIsNull,getWhatsapp, getMessageWhatsapp, getVideos} from '../../helpers/helper.rendering'
import {getTags,getBluePrints, getFakeAddres, getLocation,getTour, getLastVideo, getOperations, getType, getCustomTags, getNameProducer, getPictureProducer, getCellphoneProducer, getEmailProducer, getTours} from '../../helpers/helper.properties'
import Breadcrumbs from "../breadcrumbs";

import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

import Loading from '../../components/Loading'
import OwlCarousel from 'react-owl-carousel3';
import { updateRouter } from "../../helpers/helper.filters.jsx"
import { useLocation } from "@reach/router"
import Slider from "../slider.jsx"
import FilesPro from "../Emprendimiento/files-pro.jsx"
import { useGetPropertyQuery } from "../../redux/middlewareTokkoApi/properties.js"
import { useGetPropertyQuery as useGetPropertyMediacoreQuery } from "../../redux/mediacore/properties"
import useQueryProperty from "../../hooks/useQueryProperty.js"

const title_extras = ['Servicios','Amenities','Generales']

const options = {
    settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        iconColor: "rgba(255,255,255, 1)",
    },
    caption: {
        captionColor: "#000",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    }
};

const options_slider = {
    // loop:true,
    // smartSpeed:1000,
    // stagePadding:10,
    center:false,
    // autoplay:true,
    margin:15,
    items:3,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        992: {
            items: 3,
        },
    },
}

const About = ({similar_properties,id,loading,dispatch}) => {
    const history = useLocation();
    const [charMax,setCharMax] = useState(1000)
    const { openLightbox, closeLightbox } = useLightbox()
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_email
            global_phone
            template_version
            proFeatures{
                mediaBar
            }
            branch_office{
              contact {
                phone
                mail
                whatsapp
              }
            }
            keys{
                tokko
            }
            env{
                CLIENTID
            }
        }
    }`)

    const MediaPro = realEstate.template_version === 'PRO' ? true : false;
    const {mediaBar} = realEstate.proFeatures
    const Producer_Module = false;
    
    const slider_similar = useRef()

    // const { data: allPropertyData, isLoading } = useGetPropertyQuery({"API_KEY": realEstate.keys.tokko, "ID": id});
    // const { data: allPropertyData, isLoading } = useGetPropertyMediacoreQuery({"CLIENT_ID": realEstate.env.CLIENTID, "PROP_ID": id});
    const { allPropertyData, isLoading, isError, isFetching } = useQueryProperty(id);

    const [MapComponent, setComponent] = useState(null);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        const importedComponent = require("../map")
        setComponent(importedComponent.default);
      }
    }, []);

    const checkSimilar = (property,properties) => {
        if(properties){
            return properties.filter(p => p.id !== property?.id)
        }
    }

    const createLinkProducer = (producer) => {
            let url = ''
            if(producer){
                if(history.pathname.toLowerCase().includes("alquiler")){
                    return  "/alquiler/" + url + "producer-" + producer?.id + "-" + producer?.name?.toLowerCase().replaceAll(" ","-").replaceAll(".","").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                }
                else{
                    return  "/venta/" + url + "producer-" + producer?.id + "-" + producer?.name?.toLowerCase().replaceAll(" ","-").replaceAll(".","").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                }
            }
        return ''
    }

    return (
        <section className="ficha-about prop">
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className="col-12">
                        {
                            allPropertyData ?
                             <Breadcrumbs
                                props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: getOperations(allPropertyData)[0] , route:   "/" + getOperations(allPropertyData)[0].toLowerCase(), location: ""},
                                    { name: getType(allPropertyData) , route:   "/" + getOperations(allPropertyData)[0].toLowerCase() + "/" + getType(allPropertyData).toLowerCase(), location: ""},
                                    { name: getFakeAddres(allPropertyData) , route:   "", location: ""},
                                ]}
                                />
                                :''
                        }
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2">
                                <h4 className="mb-4 mb-lg-0">Descripción de la propiedad</h4>
                            </div>
                            <div className="col-lg-10">
                                <p className="description-ficha">{allPropertyData && 
                                    getDescription(allPropertyData,9999).map((description,index) => (
                                    <>
                                        {description}
                                        {index > 0 && <br />}
                                    </>
                                    ))
                                }
                                <>
                                    {/* {charMax === 1000 && !descriptionIsNull(allPropertyData) && <span className="d-block text-right text-expand"><a onClick={() => setCharMax(9999)}>Ver más</a></span>} */}
                                    {/* {charMax === 9999 && <span className="d-block text-right text-expand"><a onClick={() => setCharMax(1000)}>Ver menos</a></span>} */}
                                </>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class={"col-lg-12 mt-4 mt-lg-5"}>
                        {console.log(getTags(allPropertyData))}
                        {[1,2,3].map((type_id,index) => (
                            <div className={(getTags(allPropertyData).filter(tag => tag.tag_type === type_id || tag.type === type_id).length > 0 ? '' : 'd-none') }>
                                <div className="row align-items-start my-lg-4 mb-lg-5">
                                    <div className="col-lg-2">
                                        <h4 className="mb-4 mt-4 mt-lg-0">
                                            {
                                            title_extras[index] === 'Amenities' && realEstate.keys.tokko 
                                            ? 'Ambientes' 
                                            : title_extras[index] === 'Generales' && realEstate.keys.tokko 
                                                ? 'Adicionales'
                                                : title_extras[index]}</h4>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row no-gutters amenities">
                                            {allPropertyData && getTags(allPropertyData).filter(tag => tag.tag_type === type_id || tag.type === type_id).map((tag,index) => (
                                                <div className="col-lg-3 col-6 mb-3 item align-items-center d-flex">
                                                    <i className="icon-check"></i>
                                                    <p>{tag.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className={"mt-lg-4 mt-4 " + (getCustomTags(allPropertyData)?.find(tag => tag.group_name?.toLowerCase() === 'especales')?.length > 0 ? '' : 'd-none') }>
                            <div className="row">
                                <div className="col-lg-2">
                                    <h4 className="mb-4 mt-5 mt-lg-auto ">Características especiales</h4>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row no-gutters amenities">
                                        {allPropertyData && getCustomTags(allPropertyData).map((tag,index) => (
                                            tag.group_name?.toLowerCase() === 'especiales' &&
                                            <div className="col-6 mb-3 item align-items-center d-flex">
                                                <i className="icon-check"></i>
                                                <p style={{lineHeight:"1"}}>{tag.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
                </div>
                {!mediaBar &&
                    <>
                        <div class={"row global-view blueprints pt-5 mt-3 " + (getBluePrints(allPropertyData).length > 0 ? '' : ' d-none')} id="planos">
                            <div className="col-12">
                                <Slider files={getBluePrints(allPropertyData)} />
                            </div>
                        </div>
                        <div id="video" class={"row global-view pt-5 mt-3 " + (getLastVideo(allPropertyData) === undefined ? 'd-none' : '')}>
                            <div className="col-12">
                                <Slider files={getVideos(allPropertyData)} type={"videos"} />
                            </div>
                        </div>
                        <div id="tour360" class={"row global-view pt-5 mt-3 " + (getTours(allPropertyData).length === 0 ? 'd-none' : '')}>
                            <div className="col-12">
                                <Slider files={getTours(allPropertyData)} type={"tours"} />
                            </div>
                        </div>
                    </>
                }
            </div>
            {allPropertyData && <FilesPro files={allPropertyData.files || []} />}
            <div className="container-fluid">
                <div className="row global-view pt-5 pt-lg-0 mt-3 mt-lg-5 ubicacion-main">
                    <div className="col-lg-2">
                        <h4 className="section-name">Ubicación</h4>
                    </div>
                    <div className="col-lg-10">
                        <p className="prop-title mb-lg-4 mb-4 pb-lg-0 pb-1">{getFakeAddres(allPropertyData)}, {getLocation(allPropertyData)}</p>
                    </div>
                    <div className="col-12">
                        {typeof window !== 'undefined' && MapComponent 
                            ?<MapComponent data={allPropertyData} coordenadas={{ geo_lat: allPropertyData?.geo_lat, geo_long: allPropertyData?.geo_long }} />
                            :''}
                    </div>
                </div>
                <div className="row contacto-main pb-5" id="contacto-section">
                    <div className="col-lg-5 col-12">
                        <h2>Consultanos por <br className="d-none d-lg-block" /> esta  propiedad </h2>
                        <div className="content-contact">
                            {Producer_Module && 
                                <div className="div_broker_card"> 
                                    <a  target="_blank" href={createLinkProducer(allPropertyData?.producer)}>
                                        <div className="header_broker d-flex justify-content-between justify-content-lg-start">
                                            {( getPictureProducer(allPropertyData) && <img className='d-none d-lg-block' src={getPictureProducer(allPropertyData)} alt="" />)}
                                            <div className="info_broker d-flex flex-column justify-content-between">
                                                <div className="">
                                                    {( getNameProducer(allPropertyData) && <h3 className='contacto mb-1'>contacto</h3>)}
                                                    <h4 className='name_broker'>{getNameProducer(allPropertyData)}</h4>
                                                </div>
                                                <a href={createLinkProducer(allPropertyData?.producer)} className="btn btn-producer btn-white">Ver otras propiedades de {getNameProducer(allPropertyData)?.split(" ")[0]}</a>
                                            </div>
                                            {( getPictureProducer(allPropertyData) && <img className='d-block d-lg-none' src={getPictureProducer(allPropertyData)} alt="" />)}
                                        </div>
                                        <div className="contacto_broker">
                                            <hr />
                                            <a className={!getCellphoneProducer(allPropertyData) && 'd-none'} target='_blank' href={getWhatsapp(getCellphoneProducer(allPropertyData),getMessageWhatsapp({},allPropertyData,location.href))}><h5><i className="icon-whatsapp-solid "></i>+{getCellphoneProducer(allPropertyData)}</h5></a>
                                            {/* <a target="_blank" href={"https://api.whatsapp.com/send?phone=+549" + (realEstate.branch_office[0].contact.whatsapp.replaceAll("-",""))}>Whatsapp {realEstate.branch_office[0].contact.whatsapp}</a> */}
                                            <a className={"mailto " + (!getEmailProducer(allPropertyData) && 'd-none') } href={"mailto:" + getEmailProducer(allPropertyData) + "?subject=" + allPropertyData?.type?.name + " - " + allPropertyData?.fake_address +"&body=" + getMessageWhatsapp({},allPropertyData,location.href)}><h5 className="mb-0"><i className="icon-email-solid"></i>{getEmailProducer(allPropertyData)}</h5></a>
                                            <hr className="d-block d-lg-none" />
                                        </div>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-7 col-12 mt-4 mt-lg-0">
                        <div className="row justify-content-end">
                            <div className="col-lg-11">
                                <Formulario property={allPropertyData} from={'property'} />
                                <div id="alert-message"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"row mb-5 " + (checkSimilar(allPropertyData,similar_properties).length === 0 && "d-none")}>
                    <div className="col-12">
                        <h4 className="mb-5 mt-4">Propiedades similares</h4>
                    </div>
                        {checkSimilar(allPropertyData,similar_properties).map((prop,index) => (
                            index < 3 &&
                            <div className="col-lg-4">
                                <Card key={index} basic={false} property={prop}/>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    property: state.properties.property,
    similar_properties: state.properties.similar_properties,
    loading: state.properties.loading,
}),null)(About);